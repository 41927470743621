import { useState, useEffect } from "react";
import "./App.css";

const pills = [
  "I love the way you look at me",
  "You make me feel like I’m the only person in the world.",
  "With you I can be myself.",
  "I love you because we are family and friends at the same time.",
  "When we’re together, all my problems disappear.",
  "You make my heart smile.",
  "You have taught me the true meaning of love.",
  "Because I miss you… even when you’re in the next room.",
  "You are truthful and vulnerable with me.",
  "Because you are determined to make this relationship work.",
  "Because when you laugh it makes me laugh!",
  "We understand each other so well. ",
  "Your eyes smile when you laugh.",
  "Because I can be myself around you.",
  "Let’s make this a birthday to remember. It’s all about you today and I want to make you feel like the happiest woman alive.",
  "No words can express my feelings for you, honey. You are my love, my sunshine, my life! Happy birthday!",
  "Best birthday wishes to the perfect woman in the world who chose to love an imperfect man like me. Happy birthday!",
  "One universe, nine planets, 204 countries, 809 islands, 7 seas, and I had the privilege of meeting you. On your birthday, I want you to know how much you mean to me. Happy birthday.",
  "You are my life. You are my breath. You are my inspiration. Happy birthday to you!",
  "I love your smile, I love your touch, I love your stares, I love your body, I love you! You are simply irresistible. Happy birthday!",
  "A kiss here, a kiss there, a thousand kisses everywhere for your birthday! Have a lovely day and may everyone see your gorgeous smile! Happy birthday!",
  "All I want to do is hold you in my arms forever because my paradise is right in your loving arms. Happy birthday.",
  "The reason every blessed day of mine is sunny is simply because of the love you give me. Happy birthday, my love. You are and will always be my sunshine.",
  "A hundred hearts would be too few to carry all my love for you.",
  "If I had to dream up the perfect woman, she wouldn't even come close to you.",
  "I never want to stop making memories with you.",
  "And in her smile I see something more beautiful than the stars.",
  "You are my heart, my life, my one and only thought.",
  "You are my sun, my moon, and all my stars.",
  "I want you today, tomorrow, next week and for the rest of my life.",
  "How lucky am I to have someone who makes saying goodbye so hard.",
  "I want to walk through this life with you by my side. Your hand in mine. Forever.",
  "You have made my life complete, and I love you so.",
  "I love you more than I have ever found a way to say to you.",
  "If I know what love is, it is because of you.",
  "With you kissing feels like healing.",
  "Thank you for stealing my eyes from the crowd and stealing my heart from me.",
  "I love you not only for what you are but for what I am when I am with you.",
  "Please don’t doubt my love for you; it’s the only thing I’m sure of.",
  "Sometimes I wonder if love is worth fighting for, but then I remember your face and I’m ready for war.",
  "You give me the kind of feelings people write novels about.",
  "If I could give you one thing in life, I would give you the ability to see yourself through my eyes, only then would you realize how special you are to me.",
  "Everybody has an addiction, mine just happens to be you.",
  "Happy birthday to the most beautiful person inside and out. I'm so lucky to have you in my life.",
  "You make every day brighter just by being in it. Wishing you a birthday as amazing as you are.",
  "My love for you grows stronger with each passing day. I hope your birthday is filled with all the joy and happiness you deserve.",
  "Today, we celebrate the day you were born and the impact you've had on my life. Happy birthday, my love.",
  "I feel incredibly lucky to call you my girlfriend, and I can't wait to see all the amazing things you'll achieve in the coming year. Happy birthday!",
  "You are my today and all of my tomorrows. Happy Birthday, my love.",
  "Happy Birthday to the woman who gave meaning to my life.",
  "Thank you for everything you do. Let’s make the year great together. Happy Birthday!",
  "Some things just get better with age… and you’re definitely one of them!",
  "After having you in my life I have no other wish to fulfil. Happy Birthday to you my beautiful!",
  "Wishing you healthy and wealthy birthday this year, hope you always gets what you want. Happiest birthday baby!",
  "I really love the way you look at me. May we always remain together. Happy Birthday, Honey!",
  "This day is of equal importance to me as the love of my life was born on this day. Happy Birthday, Princess!",
  "To me, seeing you smile is like getting fresh air. I make it my life's work to provide you joy at all times! Cheers to another year, oxygen!",
  "A lovely princess entered the earth on this day to brighten my life. With you, I wish to live my life. Love, happy birthday.",
  "I need you to start my day, just as the world depends on the sun to do so. Happy birthday, my girlfriend.",
  "I never believed in true love before I met you. The only thing that completes me is you. Happy birthday to you, my oxygen!",
  "I forget about all my problems when you touch me. All of my pain is soothed by the way you appear. I wish you a happy birthday, peace, cure, happiness, love, and all other kind words.",
  "Since the first day we met, you kindled a fire in my heart that has been blazing ever since. I'm fortunate to have you. Birthday greetings!",
  "Words can never adequately explain how much I love you. Happy birthday to my heart's ruler.",
  "I adore you for who you are in every way. Birthday greetings!",
  "Forever is a long time, but I wouldn’t mind spending it with you",
  "I can’t blame gravity for falling for you",
  "I am fortunate to have found you, Happy Birthday!",
  "All the you are is all that I’ll ever need.",
  "With you, I am home.",
  "Can’t wait to watch you conquer the world.",
  "You complete me. Happy birthday to my Iubire!",
  "When I saw you, I fell in love, and you smiled because you knew it.",
  "Whatever our souls are made of, his and mine are the same.",
  "Alone we can do so little; together we can do so much.",
  "It was love at first sight, at last sight, at ever and ever sight.",
  "Better than I was, more than I am, and all of this happened by taking your hand.",
  "You are the sunshine that makes my day.",
  "You're the queen of our castle.",
  "You're the best nap buddy in the world.",
  "I will stand by you through anything.",
  "Our love story is endless.",
  "Every day, I promise to prove that I deserve your love.",
  "With every moment that passes, I love you even more.",
];

// steps
const passwords = [
  "7684", // cup
  // go to nicolclaudia.com (inside candle)
  // show happy birthday message (keep always top)
  // tell the game that you want to play
  // once you find the gift, press I found button
  "6243", // coffee machine
  // tell the game that you want to play
  // once you find the gift, press I found button
  "3795", // socks, and cat toy
  // tell the game that you want to play
  // once you find the gift, press I found button
  "4391", // camera
  // tell the game that you want to play
  // once you find the gift, press I found button
  "1391", // photos
  // pills * (ask tug to give it to you)
];

function App() {
  const [step, setStep] = useState(1);
  const [step1Value, setStep1Value] = useState(null);
  const [step2Value, setStep2Value] = useState(null);
  const [step3Value, setStep3Value] = useState(null);
  const [step4Value, setStep4Value] = useState(null);
  const [step5Value, setStep5Value] = useState(null);
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (step === 5) {
      runSlider();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const runSlider = () => {
    const slideshowImages = [
      "/assets/images/1.JPG",
      "/assets/images/2.JPG",
      "/assets/images/3.JPG",
      "/assets/images/4.JPG",
      // "/assets/images/beach-1.JPG",
      // "/assets/images/beach-2.JPG",
      "/assets/images/berlin-1.JPG",
      // "/assets/images/berlin-2.JPG",
      "/assets/images/berlin-3.JPG",
      "/assets/images/berlin-4.JPG",
      "/assets/images/berlin-5.JPG",
      "/assets/images/berlin-6.JPG",
      // "/assets/images/cruise-1.JPG",
      // "/assets/images/cruise-2.JPG",
      "/assets/images/cruise-3.JPG",
      "/assets/images/game.JPG",
      "/assets/images/halloween-1.JPG",
      "/assets/images/halloween-2.JPG",
      "/assets/images/home-1.JPG",
      "/assets/images/home-2.JPG",
      "/assets/images/out-1.JPG",
      "/assets/images/out-2.JPG",
      "/assets/images/pool.JPG",
    ];
    let currentImageIndex = 0;

    function transitionSlideshow() {
      setImage(slideshowImages[currentImageIndex]);
      currentImageIndex = (currentImageIndex + 1) % slideshowImages.length;

      // if (currentImageIndex + 1 === slideshowImages.length) {
      //   const text = "Reasons why I love you.";
      //   const speed = 100; // 100 milliseconds between characters
      //   const element = document.getElementById("typed-text");
      //   setIsLoading(true);
      //   typeText(text, 0, speed, element, function () {
      //     setIsLoading(false);
      //   });
      // }
    }

    setInterval(transitionSlideshow, 2000);
  };

  function typeText(text, i, speed, element, callback) {
    if (i < text.length) {
      element.innerHTML += text.charAt(i);
      i++;
      setTimeout(function () {
        typeText(text, i, speed, element, callback);
      }, speed);
    } else {
      callback();
    }
  }

  return (
    <div className="flex flex-col min-h-screen">
      {/* <header className="bg-pink-500">
        <div className="container mx-auto px-4 py-6">
          <h1 className="text-white text-xl font-bold">My Iubire</h1>
        </div>
      </header> */}

      <section className="flex-1">
        <div className="flex flex-col justify-center items-center bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 w-full h-screen">
          <h1 className="text-4xl font-bold mb-4">Happy Birthday</h1>
          <h1 className="text-4xl font-bold mb-4">Iubire</h1>

          {step >= 5 && (
            <p className="text-white text-lg mb-8">
              <div id="typed-text"></div>
            </p>
          )}

          {image && (
            <div className="image-container mb-8">
              <img
                id="slideshow-img"
                className="rounded-lg"
                style={{ height: "400px" }}
                src={image}
                alt=""
              />
            </div>
          )}

          {(() => {
            if (step === 1) {
              return (
                <div className="flex flex-col align-center items-center">
                  <label
                    htmlFor="number-input"
                    className="text-white block mb-4"
                  >
                    Enter the code:
                  </label>
                  <input
                    value={step1Value}
                    type="number"
                    id="number-input"
                    className="bg-gray-200 rounded py-2 px-4 mb-4"
                    pattern="\d+"
                    required
                    onChange={(e) => setStep1Value(e.target.value)}
                  />

                  <button
                    className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-700 mb-8 disabled:bg-gray-400 disabled:text-gray-500 disabled:hover:bg-gray-400"
                    onClick={() => setStep(2)}
                    disabled={step1Value != "7684"}
                  >
                    Click me to Start!
                  </button>
                </div>
              );
            } else if (step === 2) {
              return (
                <div className="flex flex-col align-center items-center text-center">
                  <p className="text-white text-md mb-2">
                    We are going to play a game, I want you to find something
                    precious
                  </p>

                  <p className="text-white text-md mb-2">
                    Tip: băutura preferată a iubitei mele
                  </p>

                  <label
                    htmlFor="number-input"
                    className="text-black block mb-4"
                  >
                    Enter the code:
                  </label>
                  <input
                    value={step2Value}
                    type="number"
                    id="number-input"
                    className="bg-gray-200 rounded py-2 px-4 mb-4"
                    pattern="\d+"
                    required
                    onChange={(e) => setStep2Value(e.target.value)}
                  />

                  <button
                    className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-700 mb-8 disabled:bg-gray-400 disabled:text-gray-500 disabled:hover:bg-gray-400"
                    onClick={() => setStep(3)}
                    disabled={step2Value != "6243"}
                  >
                    Take me to the next step!
                  </button>
                </div>
              );
            } else if (step === 3) {
              return (
                <div className="flex flex-col align-center items-center text-center">
                  <p className="text-white text-md mb-2">
                    I want you to find something that makes you so happy
                  </p>

                  <p className="text-white text-md mb-2">
                    Tip: cea mai drăguță creatură
                  </p>

                  <label
                    htmlFor="number-input"
                    className="text-black block mb-4"
                  >
                    Enter the code:
                  </label>
                  <input
                    value={step3Value}
                    type="number"
                    id="number-input"
                    className="bg-gray-200 rounded py-2 px-4 mb-4"
                    pattern="\d+"
                    required
                    onChange={(e) => setStep3Value(e.target.value)}
                  />

                  <button
                    className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-700 mb-8 disabled:bg-gray-400 disabled:text-gray-500 disabled:hover:bg-gray-400"
                    onClick={() => setStep(4)}
                    disabled={step3Value != "3795"}
                  >
                    Take me to the next step!
                  </button>
                </div>
              );
            } else if (step === 4) {
              return (
                <div className="flex flex-col align-center items-center text-center">
                  <p className="text-white text-md mb-2">
                    I want you to find something that leaves memories
                  </p>

                  <p className="text-white text-md mb-2">
                    Tip: este tehnologie, iubito
                  </p>

                  <label
                    htmlFor="number-input"
                    className="text-black block mb-4"
                  >
                    Enter the code:
                  </label>
                  <input
                    value={step4Value}
                    type="number"
                    id="number-input"
                    className="bg-gray-200 rounded py-2 px-4 mb-4"
                    pattern="\d+"
                    required
                    onChange={(e) => setStep4Value(e.target.value)}
                  />

                  <button
                    className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-700 mb-8 disabled:bg-gray-400 disabled:text-gray-500 disabled:hover:bg-gray-400"
                    onClick={() => setStep(5)}
                    disabled={step4Value != "4391"}
                  >
                    Take me to the next step!
                  </button>
                </div>
              );
            } else if (step === 5) {
              return (
                <div className="flex flex-col align-center items-center text-center">
                  <p className="text-white text-md mb-2">
                    Tip: priviți mai sus
                  </p>

                  <label
                    htmlFor="number-input"
                    className="text-black block mb-4"
                  >
                    Enter the code:
                  </label>
                  <input
                    value={step5Value}
                    type="number"
                    id="number-input"
                    className="bg-gray-200 rounded py-2 px-4 mb-4"
                    pattern="\d+"
                    required
                    onChange={(e) => setStep5Value(e.target.value)}
                  />

                  <button
                    className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-700 mb-8 disabled:bg-gray-400 disabled:text-gray-500 disabled:hover:bg-gray-400"
                    onClick={() => setStep(6)}
                    disabled={step5Value != "1391"}
                  >
                    Take me to the last step!
                  </button>
                </div>
              );
            } else if (step === 6) {
              return (
                <div className="flex flex-col align-center items-center text-center">
                  <p className="text-white text-md mb-2">
                    Ask me for your gift!
                  </p>

                  <p className="text-white text-md mb-2">
                    Tip: Este mult mai drăguț decât cel mai bun tort făcut
                    vreodată.
                  </p>
                </div>
              );
            }
          })()}

          {/* {!isLoading && step === 1 && (
            <button
              className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-700 mb-8"
              onClick={() => setStep(step + 1)}
            >
              Click me to Start!
            </button>
          )} */}
        </div>
      </section>

      {/* <footer className="bg-gray-200 py-4 fixed bottom-0 w-full">
        <div className="container mx-auto flex flex-wrap justify-end items-center">
          <div className="w-full md:w-auto text-center md:text-right">
            <p>With Love</p>
          </div>
        </div>
      </footer> */}
    </div>
  );
}

export default App;
